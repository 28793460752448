import React from "react"

import SEO from "../components/SeoMeta"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div style={{ margin: "115px" }}>
      <h1>PAGE NOT FOUND</h1>
    </div>
  </>
)

export default NotFoundPage
